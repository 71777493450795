import React, { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useAppState from "../store/appState";
import { useSearchParams } from "react-router-dom";
import { T, useTolgee } from "@tolgee/react";
import axios from "../axios";

function Filter({ activeFilters, setActiveFilters, categories, materials, allMaterials }) {
  const [searchParams, setSearchParams] = useSearchParams();

  //const queryClient = useQueryClient();
  //const categories = queryClient.getQueryData(["categoryData"]);

  //const { searchResults, setFilterResults } = useAppState();

  //const activeFiltersString = searchParams.get("activeFilters");
  //const activeFilters = activeFiltersString ? JSON.parse(activeFiltersString) : {};

  // const setActiveFilters = (selectedFilters) => {
  //   // setSearchParams({ activeFilters: JSON.stringify(selectedFilters) });
  //   const newParams = new URLSearchParams(searchParams);
  //   if (selectedFilters == null || Object.keys(selectedFilters).length === 0) {
  //     newParams.delete("activeFilters");
  //   } else {
  //     newParams.set("activeFilters", JSON.stringify(selectedFilters));
  //   }
  //   setSearchParams(newParams);
  // };

  const { supportedLanguages } = useAppState();
  const tolgee = useTolgee(["language"]);

  function getLanguageKey() {
    var languageCode = tolgee.getLanguage();
    return supportedLanguages.find((l) => l.languageCode == languageCode)?.languageNumber ?? 0;
  }

  const countSelectedFilterItems = (activeFilters, categories) => {
    const selectedItemsCount = {};

    // Iterate over the categories in activeFilters
    Object.keys(activeFilters).forEach((category) => {
      // Initialize the count for this category to 0
      selectedItemsCount[category] = 0;

      // Get the array of items for this category
      const categoryItems = categories[category]; //.find((cat) => Object.keys(cat)[0] === category)?.[category];

      if (categoryItems) {
        // Iterate over the items in this category
        categoryItems.forEach((item) => {
          // Check if the keyStr of the item is included in the active filters for this category
          if (activeFilters[category].includes(item.key)) {
            // If the item is selected, increment the count for this category
            selectedItemsCount[category]++;
          }
        });
      }
    });

    return selectedItemsCount;
  };

  const handleCheckboxChange = (category, item) => {
    const params = new URLSearchParams(searchParams);
    params.set("currentPage", 0);
    params.set("itemOffset", 0);
    setSearchParams(params.toString());

    const updatedFilterItems = { ...activeFilters };

    if (!updatedFilterItems[category]) {
      updatedFilterItems[category] = [];
    }

    const index = updatedFilterItems[category].indexOf(item);
    // Toggle the item in the array
    if (index === -1) {
      // Item not found, add it to the array
      updatedFilterItems[category].push(item);
      try {
        // Send to backend
        axios.get("/materials/filter", {
          params: {
            filterType: category,
            filterValue: item,
            filterText: categories[category].find((c) => c.key == item).value,
            language: getLanguageKey(),
          },
        });
      } catch (error) { }
    } else {
      // Item found, remove it from the array
      updatedFilterItems[category].splice(index, 1);
      if (updatedFilterItems[category].length === 0) delete updatedFilterItems[category];
    }
    // Update the searchParams
    setActiveFilters(updatedFilterItems);
  };

  const filteredResults = (categoryName, item) => {
      const keys = ["series", "use", "typeOfDocument", "category"];

      for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          let result = false;
          if (categoryName != key) {
              const values = activeFilters[key];
              if (values?.length > 0) {
                  result = allMaterials.filter((material) => material[categoryName].value == item.value && values.includes(material[key].key)).length == 0;
              }
          }
          if (result) return true;
      }

      return false;
  };

  return (
    <div className="">
      <div className="flex flex-col mb-[200px]">
        {Object.keys(categories).map((category, index) => (
          <div key={index}>
            {index !== 0 && <hr className="mt-0" />}
            <details className="[&_svg]:open:-rotate-180   text-black pt-[15px] pb-[20px] [&_h4]:open:hidden">
              <summary className="flex cursor-pointer list-none items-center justify-between gap-4 [&::-webkit-details-marker]:hidden">
                <div className="flex items-center ">
                  <h3 className="leading-[28px] text-[18px] font-inter font-semibold ">
                    <T keyName={category} />
                  </h3>

                  {countSelectedFilterItems(activeFilters, categories)[category] > 0 ? (
                    <>
                      <h4 className="bg-grey-2-sp leading-[16px] flex ml-2 items-center justify-center rounded-full text-[14px] font-semibold font-roboto text-white h-6 w-6">
                        {countSelectedFilterItems(activeFilters, categories)[category]}
                      </h4>
                    </>
                  ) : null}
                </div>

                <svg
                  className="rotate-0 transform"
                  fill="none"
                  height="20"
                  width="20"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </summary>

              <div className="flex flex-col text-black   mt-2">
                {categories[category].length > 0 &&
                  categories[category]
                    .filter((item) => filteredResults(category, item) == false) 
                    .map((item, itemIndex) => {
                      return (
                        <label key={`${category}-${itemIndex}`} className="w-full flex items-center my-2" >
                      <input
                        id={item}
                        className="peer relative appearance-none shrink-0 w-5 h-5 border-2 border-black rounded-md ml-1 mr-2 bg-white focus:outline-none focus:ring-offset-0 focus:ring-1 focus:ring-blue-100 checked:bg-white checked:border-black"
                        type="checkbox"
                        checked={activeFilters[category]?.includes(item.key) || false}
                        onChange={() => handleCheckboxChange(category, item.key)}
                      />
                      <svg
                        className="absolute p-1  w-5 h-5 pointer-events-none hidden peer-checked:block stroke-black ml-1  outline-none"
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 7.64331L1.41 9L8 2.70382L10.58 5.15924L12 3.8121L8 7.37351e-08L0 7.64331Z" fill="black" />
                      </svg>
                      <p className="font-inter leading-[18px]  text-[14px] font-normal">{item.value}</p>
                    </label>
                  );
                })}
              </div>
            </details>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Filter;
