import SearchBox from "../components/SearchBox";
import backgroundMobile from "../assets/images/homepage-banner-mobile.jpg";
import backgroundDesktop from "../assets/images/homepage-banner-desktop.jpg";
import { T } from "@tolgee/react";
import useAppState from "../store/appState";
import CryptoJS from "crypto-js";

function HomePage() {
  const { isMobile } = useAppState();

  const secretKey = "YiVrHI8yUhI3mHAIxfdthAGqGOWUdHVC";
  const plaintext = "disd-team-rock";

  // Decrypt
  const bytes = CryptoJS.AES.decrypt("U2FsdGVkX19Ka0tsJ6FxPdvABw7Ne8xdy+9ftloNq9o=", secretKey);
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
  //console.log("Decrypted:", decryptedText);

  return (
    <section className="bg-white">
      <div className="relative">
        <img className=" w-full" src={isMobile ? backgroundMobile : backgroundDesktop} alt="Background"></img>
        <div className="w-full px-4 lg:px-0 mx-auto mt-[27px] lg:absolute text-black lg:text-grey-1-sp font-roboto md:items-center flex flex-col lg:translate-x-[-50%] lg:translate-y-[-50%] lg:top-[44%] lg:left-1/2">
          <p className="leading-[48px] lg:leading-[70px] text-[42px] lg:text-[64px] font-semibold lg:drop-shadow-lg">
            {isMobile ? <T keyName="HomePage.h1.title.mobile" /> : <T keyName="HomePage.h1.title.desktop" />}
          </p>
          <h2 className="leading-[24px] mt-[17px]  lg:mb-[28px] lg:mt-[11px] text-[18px] lg:text-[24px] font-medium lg:drop-shadow-lg ">
            <T keyName="HomePage.h2.subtitle" />
          </h2>
          <div className="mt-[33px] mb-[128px] lg:mb-[0px] lg:mt-[0px]  flex">
            <SearchBox />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomePage;
