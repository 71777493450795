import axios from "axios";
import { webAPIUrl } from "./AppSetting";
import qs from "qs";

const instance = axios.create({
  baseURL: webAPIUrl,
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params, { allowDots: true, skipNulls: true }),
  // headers:{
  //      'Authorization': user != null ? `Basic ${user.token}` : ""
  // }
});

export default instance;
