import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import useAppState from "./store/appState";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IntlProvider } from "react-intl";
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { Tolgee, DevTools, TolgeeProvider, FormatSimple, LanguageDetector, LanguageStorage, BackendFetch } from "@tolgee/react";

import ReactGA from 'react-ga4';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .use(LanguageDetector())
  .use(LanguageStorage()) // comment out for testing auto language detection purposes
  .use(BackendFetch())
  .init({
    // language: 'es',
    availableLanguages: ["en", "es", "fr"],
    defaultLanguage: "en",

    //apiUrl: 'https://translations.paramet.net',
    //apiKey: 'tgpak_geyf64dhgf2dqz3vhe4tq5dmmnwgcmlgoy2wkntugm3gm4a',

    // need to change back to an environment variable!
    // apiUrl: import.meta.development.VITE_TOLGEE_API_URL,
    // apiKey: import.meta.env.development.VITE_TOLGEE_API_KEY,
  });

ReactGA.initialize('G-387734574');
ReactGA.send("pageview");

function AppWrapper() {
  const { setCountry, setIsMobile } = useAppState();

  // useEffect(() => {
  //   getIPinfo().then((result) => {
  //     setCountry({ countryCode: result.countryCode, countryName: result.country }); // { countryCode: 'GB', countryName: 'United Kingdom' }
  //   });

  // }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize(); // Set the initial value

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <IntlProvider locale={tolgee.getLanguage()}>
      <QueryClientProvider client={queryClient}>
        <App />
        {/* <ReactQueryDevtools/> */}
      </QueryClientProvider>
    </IntlProvider>
  );
}

root.render(
  <BrowserRouter>
    <TolgeeProvider tolgee={tolgee}>
      <AppWrapper />
    </TolgeeProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
