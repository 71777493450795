import { LanguageDetector, useTolgee } from "@tolgee/react";
import { useState, useRef, useEffect } from "react";
import useAppState from "../store/appState";
import { useSearchParams } from "react-router-dom";

export const LanguageSelect = () => {
  const { supportedLanguages } = useAppState();

  const dropdownRef = useRef(null);

  const tolgee = useTolgee(["language"]);
  const [selectedLanguage, setSelectedLanguage] = useState(tolgee.getLanguage());
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  // const sortByQuery = searchParams.get('sort_by'); // "average_weight"
  // const orderQuery = searchParams.get('order'); // "asc"
  const languageQuery = searchParams.get("lang"); // language

  const setLanguage = (language) => {
    // copy existing queries to avoid mutation
    const newParams = new URLSearchParams(searchParams);
    // set the lang query
    newParams.set("lang", language);
    setSearchParams(newParams);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const changeLanguage = (language) => {
    tolgee.changeLanguage(language.languageCode);
    setSelectedLanguage(language.languageCode);
    setIsDropdownOpen(false);
    setLanguage(language.languageNumber);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    var selectedLanguageObject = supportedLanguages.find((language) => language.languageCode === selectedLanguage);

    if (languageQuery != undefined) {
      selectedLanguageObject = supportedLanguages.find((language) => language.languageNumber === Number(languageQuery));
    }

    //console.log(selectedLanguageObject); // should be a language object i can get languageCode from
    if (selectedLanguageObject) {
      const newLanguageCode = selectedLanguageObject.languageCode;
      //console.log(newLanguageCode); // This will output 'en' for randomNumber = 1
      //console.log(`language query in URL is ${languageQuery} so setting tolgee language to ${newLanguageCode}`);
      tolgee.changeLanguage(newLanguageCode);
    } else {
      //console.log("No language found for the given number, falling back to english.");
      // tolgee.changeLanguage('es');
      tolgee.changeLanguage("en");
    }
  }, []);

  return (
    <div className="z-50 relative font-roboto select-none" ref={dropdownRef}>
      <div className="flex flex-row align-middle items-center cursor-pointer" onClick={toggleDropdown}>
        <span className=" md:text-base  text-grey-4 font-semibold pr-1 leading-4 hover:underline">{tolgee.getLanguage().toUpperCase()}</span>
        <svg className="rotate-0 transform" fill="none" height="18" width="18" stroke="#706f6f" strokeLinecap="square" strokeLinejoin="square" strokeWidth="3" viewBox="0 0 24 24">
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </div>
      {isDropdownOpen && (
        <ul className="absolute right-0 md:left-0 mt-1 bg-white border border-gray-300  py-1 z-10 w-max">
          {supportedLanguages.map((language, index) => (
            <li
              key={index}
              onClick={() => {
                changeLanguage(language);
              }}
              className={`flex leading-5 items-center px-4 py-1 hover:bg-grey-1 cursor-pointer ${
                selectedLanguage === language.languageCode ? "bg-red-sp hover:bg-red-sp text-white" : ""
              }`}
            >
              <span>{language.languageName}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelect;

// // *** non-complete version i was working on before s&p asked NOT to have a different country be selected
// // by the user, may be useful for potential admin account?

// // import { useTolgee } from '@tolgee/react';
// // import { useState, useRef, useEffect } from 'react';
// // import useAppState from '../store/appState'

// // // import { ChevronDownIcon } from '@heroicons/react/24/solid';

// // export const LanguageSelect = () => {

// //     const { country, setCountry, supportedLanguages, supportedCountries } = useAppState()
// //     const tolgee = useTolgee(['language']);
// //     const [selectedLanguage, setSelectedLanguage] = useState(tolgee.getLanguage());
// //     const [selectedCountryInfo, setSelectedCountryInfo] = useState(country);
// //     const [isDropdownOpen, setIsDropdownOpen] = useState(false);

// //     const toggleDropdown = () => {
// //         setIsDropdownOpen(!isDropdownOpen);
// //     };

// //     const handleSubmit = (e) => {
// //         e.preventDefault();
// //         setIsDropdownOpen(false);
// //         setCountry(selectedCountryInfo);
// //         tolgee.changeLanguage(selectedLanguage)
// //     }

// //     const handleCountryChange = (event) => {
// //         const countryCode = event.target.value;
// //         const selectedCountry = supportedCountries.find(country => country.countryCode === countryCode);
// //         setSelectedCountryInfo(selectedCountry);
// //     };

// //     const handleLanguageChange = (event) => {
// //         const language = event.target.value;
// //         setSelectedLanguage(language);
// //     };

// //     return (
// //             <div className="z-50 relative font-customSans">
// //                 <div className="flex flex-row align-middle items-center cursor-pointer" onClick={toggleDropdown}>
// //                     <span className="text-sm xl:text-sm 2xl:text-base font-semibold ml-0.5 leading-snug">
// //                         language : {tolgee.getLanguage()}
// //                         <br></br>
// //                         country : {country.countryName} / {country.countryCode}
// //                         <br></br>
// //                         temp country: {selectedCountryInfo.countryName}
// //                     </span>
// //                 </div>
// //                 {isDropdownOpen && (

// //                     <div className="fixed inset-0 bg-grey-50 bg-gray-100 bg-opacity-75" onClick={toggleDropdown}>

// //                         <form className="bg-purple-500 flex flex-col justify-center gap-y-10 items-center w-[500px] "
// //                             onClick={(e) => e.stopPropagation()}
// //                             onSubmit={(e) => handleSubmit(e)}
// //                         >

// //                             <div className="flex flex-col">
// //                                 <label htmlFor="country-select">Country</label>
// //                                 <select name="country" id="country-select" value={selectedCountryInfo.countryCode} onChange={handleCountryChange}>
// //                                     {supportedCountries.map((country, index) => (
// //                                         <option key={index} value={country.countryCode}>
// //                                             {country.countryName}
// //                                         </option>
// //                                     ))}
// //                                 </select>
// //                             </div>

// //                             <div className="flex flex-col">
// //                                 <label htmlFor="language-select">Language</label>
// //                                 <select name="language" id="language-select" value={selectedLanguage} onChange={handleLanguageChange}>
// //                                     {supportedLanguages.map((language, index) => (
// //                                         <option key={index} value={language}>
// //                                             {language}
// //                                         </option>
// //                                     ))}
// //                                 </select>
// //                             </div>
// //                             <button type="submit" className="bg-red-500 p-2">GO! ✅</button>
// //                         </form>
// //                     </div>

// //                 )}
// //             </div>
// //         );
// //     )
// // };

// // export default LanguageSelect;

// *** example from northcoders notes ***
// src/components/Animals.jsx

// import { useEffect } from 'react';
// import { useSearchParams } from 'react-router-dom';

// const LanguageSelect = () => {
//     const [searchParams, setSearchParams] = useSearchParams();
//     const sortByQuery = searchParams.get('sort_by'); // "average_weight"
//     const orderQuery = searchParams.get('order'); // "asc"

//     const setSortOrder = (direction) => {
//         // copy existing queries to avoid mutation
//         const newParams = new URLSearchParams(searchParams);
//         // set the order query
//         newParams.set('order', direction);
//         setSearchParams(newParams);
//     };

//     useEffect(() => {
//         // fetch new data based on the queries
//     }, [sortByQuery, orderQuery]);

//     return (
//         <section>
//             <h2>Topics</h2>
//             <button className="bg-red-300 mr-2" onClick={() => setSortOrder('asc')}>Ascending</button>
//             <button className="bg-red-300" onClick={() => setSortOrder('desc')}>Descending</button>
//         </section>
//     );
// };

// export default LanguageSelect;
