import SearchBox from "../components/SearchBox";
import ResultsPageSubHeader from "../components/ResultsPageSubHeader";
import FilterDesktop from "../components/FilterDesktop";
import { Link, useParams } from "react-router-dom";
import { T } from "@tolgee/react";
import ResultsDisplay from "../components/ResultsDisplay";
import { useQueryClient } from "@tanstack/react-query";
import LoadingIndicator from "../components/LoadingIndicator";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useFilter } from "../hooks/useFilter";
import useAppState from "../store/appState";
import Overlay from "../components/Overlay";
import CompanySelection from "../components/CompanySelection";

function ResultsPage() {
  const { displayMobileFilterModal } = useAppState();
  const { materials, activeFilters, setActiveFilters, categories, isFetching, sortby, setSortby, companies, data, defaultCountry } = useFilter();

  return (
    <div className="bg-white">
      {displayMobileFilterModal && <Overlay activeFilters={activeFilters} setActiveFilters={setActiveFilters} categories={categories} materials={materials} data={data} />}
      <ResultsPageSubHeader />

      <section className=" mx-4 lg:ml-[114px] lg:mr-[115px] mt-[19px] mb-[30px] lg:mb-[10px]  lg:mt-[46px] ">
        <p className="lg:hidden mb-[19px] text-grey-4 ">
          <Link className="text-grey-1-sp underline decoration-1 underline-offset-2 font-semibold" to="/" rel="noopener noreferrer">
            <T keyName="ResultsPage.breadcrumb.homepage-link" />
          </Link>
          &nbsp;/&nbsp;
          <T keyName="ResultsPage.breadcrumb.results" />
        </p>
        <div className="flex mb-[28px] lg:mb-[34px] ">
          <SearchBox />
          {companies && <CompanySelection companies={companies} />}
        </div>
        {!isFetching && materials && (
          <div className="grid gap-0 lg:gap-[102px] grid-cols-[0_1fr] lg:grid-cols-[304px_1fr] mb-[80px]">
            <div className="overflow-hidden ">
              <div className="hidden lg:block">
                <FilterDesktop activeFilters={activeFilters} setActiveFilters={setActiveFilters} categories={categories} materials={materials} allMaterials={data} />
              </div>
            </div>
            <div className="col-start-2">
              <ResultsDisplay materials={materials} activeFilters={activeFilters} sortby={sortby} setSortby={setSortby} defaultCountry={defaultCountry} />
            </div>
          </div>
        )}
      </section>

      <div className="flex flex-col  text-center font-inter leading-[28px] text-[18px] lg:leading-[24px] lg:text-[16px] font-semibold">
        {isFetching && (
          <div className="h-[25vh]">
            <LoadingIndicator />
          </div>
        )}

        {!isFetching && !materials && (
          <div className="h-[25vh]">
            <T keyName="ResultsPage.p.no-results-found" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ResultsPage;
