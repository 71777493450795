import OpenInNewIcon from "../assets/icons/open_in_new.svg"
import SandPLogo from "../assets/images/s-and-p-logo-white.png"
import { T } from "@tolgee/react"
import { useTranslate } from '@tolgee/react'
import { useTolgee } from '@tolgee/react';
import { SocialIcon } from 'react-social-icons'

function NationalIPSocials() {
    return (
        <>
            <SocialIcon target="_blank" bgColor=" white" fgColor="none" style={{ height: 36, width: 36 }} url="https://www.linkedin.com/company/soler-palau-ventilation-group/" />
            <SocialIcon target="_blank" bgColor=" white" fgColor="none" style={{ height: 36, width: 36 }} url="https://www.linkedin.com/company/sp-ventilacion-espana" />
            <SocialIcon target="_blank" bgColor=" white" fgColor="none" style={{ height: 36, width: 36 }} url="http://www.youtube.com/user/SyPVentilation/" />
            <SocialIcon target="_blank" bgColor=" white" fgColor="none" style={{ height: 36, width: 36 }} url="https://www.instagram.com/sp.ventilacion/" />
            <SocialIcon target="_blank" bgColor=" white" fgColor="none" style={{ height: 36, width: 36 }} url="https://www.facebook.com/sp-espana/" />
            <SocialIcon target="_blank" bgColor=" white" fgColor="none" style={{ height: 36, width: 36 }} url="https://twitter.com/SP_espana/" network="x" />
        </>
    )
}

function InternationalIPSocials() {
    return (
        <>
            <SocialIcon target="_blank" bgColor=" white" fgColor="none" style={{ height: 36, width: 36 }} url="https://www.youtube.com/@SPventilation" />
            <SocialIcon target="_blank" bgColor=" white" fgColor="none" style={{ height: 36, width: 36 }} url="https://www.linkedin.com/company/soler-palau-ventilation-group/" />
        </>
    )
}

function Footer() {
    const { t } = useTranslate()
    const tolgee = useTolgee(['language']);
    const language = tolgee.getLanguage();

    return (
        <footer className="bg-grey-2-sp text-white font-roboto py-8">
            <div className="grid grid-cols-1 lg:grid-cols-2  lg:w-[85%] mx-auto">
                <img src={SandPLogo} className="mx-auto w-[172px] lg:w-[250px]  mt-[20px] lg:ms-0"
                    alt={t({ key: 'Footer.img.SandPLogo' })} />

                <div className=" flex gap-x-3.5 items-start  lg:order-3 mx-auto lg:me-0 mt-[40px] lg:mt-[59px]  lg:mt-[0px]">
                    {language === 'es' ? <NationalIPSocials /> : <InternationalIPSocials />}
                </div>

                <div className=" items-center justify-center flex gap-x-6 lg:gap-x-12  font-base font-semibold lg:col-start-2 lg:me-0 mx-auto  mt-[32px] lg:mt-[29px] ">
                    <a href="https://www.solerpalau.com/en-en/customer-service/" target="_blank" rel="noopener noreferrer"><T keyName="Footer.a.contact-link" /></a>
                    <a href="https://www.solerpalau.com/en-en/cookies/" target="_blank" rel="noopener noreferrer" className="flex items-center"><T keyName="Footer.a.cookies-link" />&nbsp;<img src={OpenInNewIcon} className="inline-block" alt={t({ key: 'Footer.a.cookies-link' })} /></a>
                    <a href="https://www.solerpalau.com/en-en/legal-disclaimer/" target="_blank" rel="noopener noreferrer" className="flex items-center"><T keyName="Footer.a.legal-link" />&nbsp;<img src={OpenInNewIcon} className="inline-block" alt={t({ key: 'Footer.a.legal-link' })} /></a>
                </div>
                <div className=" h-[1px] mb-[26px] lg:mb[21px] lg:col-span-2 mx-auto mt-[32px] lg:mt-[26px] lg:w-[100%] bg-grey-1 w-[88%]"></div>
                <div className="font-base mx-auto pb-[34px] lg:pb-[37px] lg:ms-0">
                    <T keyName="Footer.copyright" />
                </div>
            </div>
        </footer>
    )
}

export default Footer;