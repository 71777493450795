import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ResultsPage from "./pages/ResultsPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useQueries } from "@tanstack/react-query";
import Overlay from "./components/Overlay";
import useAppState from "./store/appState";
import Redirect from "./components/Redirect";
import NotFound404 from "./pages/NotFound404";
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

function App() {

  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  const setScrollbarWidth = useAppState((state) => state.setScrollbarWidth);

  useEffect(() => {
    const scrollbarWidth = getScrollbarWidth();
    setScrollbarWidth(scrollbarWidth);
  }, [setScrollbarWidth]);

  function getScrollbarWidth() {
    const div = document.createElement('div');
    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';
    document.body.appendChild(div);
    const scrollbarWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);
    return scrollbarWidth;
  }

  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/results/:seriesId?" element={<ResultsPage />} />
        <Route path="/results/:companyId/:seriesId" element={<ResultsPage />} />
        <Route path="/series/:seriesId" element={<Redirect />} />
        <Route path="/series/:companyId/:seriesId" element={<Redirect />} />
        <Route path="/disd/:token" element={<Redirect />} />
        <Route path="*" element={<NotFound404 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
