import subHeaderImageMobile from "../assets/images/resultspage-banner-mobile.jpg"
import subHeaderImageDesktop from "../assets/images/resultspage-banner-desktop.jpg"
import { T } from "@tolgee/react";
import useAppState from '../store/appState';


function ResultsPageSubHeader() {

    const { isMobile } = useAppState();

    return (
        <section className="relative">
            <div className="h-[309px] lg:h-[400px]" style={{ backgroundImage: `url(${isMobile ? subHeaderImageMobile : subHeaderImageDesktop})`, backgroundSize: "cover", backgroundPosition: "right" }}></div>
            <div className="absolute top-1/2 ml-[22px] mr-[22px] lg:ml-[117px] lg:mr-[117px] transform -translate-y-1/2  flex flex-col   text-white text-start font-roboto ">
                <h1 className="font-semibold  lg:leading-[70px] text-[30px] lg:text-[64px]"><T keyName="SubHeaderResults.h1.title" /></h1>
                <h2 className="font-medium text-[18px] lg:text-[24px]"><T keyName="SubHeaderResults.h2.subtitle" /></h2>
                {/* <button
                    className="rounded-[50px] mt-4 lg:mt-6 w-fit leading-[24px] inline-block py-3 px-10 border-[1px] border-white font-normal text-[16px] bg-[#303030]/[.50] hover:bg-cta-button"
                    onClick={() => window.open('https://www.solerpalau.com/', '_blank', 'noopener noreferrer')}
                >
                    <T keyName="SubHeaderResults.a" />
                </button> */}
            </div>
        </section>
    )
}

export default ResultsPageSubHeader