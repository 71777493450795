import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useAppState from "../store/appState";
import clear from "../assets/icons/clear.svg";
import { T, useTolgee } from "@tolgee/react";
import { useSearchParams } from "react-router-dom";
import { useFilter } from "../hooks/useFilter";
import axios from "../axios";

function FilterDesktop({ activeFilters, setActiveFilters, categories, materials, allMaterials }) {
  const maxItemsToShow = 6; // Maximum items to display initially for each filter category
  const [itemsToShow, setItemsToShow] = useState({}); // Track the number of items to display for each category of filter
  const [searchParams, setSearchParams] = useSearchParams();

  const { supportedLanguages } = useAppState();
  const tolgee = useTolgee(["language"]);

  function getLanguageKey() {
    var languageCode = tolgee.getLanguage();
    return supportedLanguages.find((l) => l.languageCode == languageCode)?.languageNumber ?? 0;
  }

  const handleCheckboxChange = (category, item) => {
    const params = new URLSearchParams(searchParams);
    params.set("currentPage", 0);
    params.set("itemOffset", 0);
    setSearchParams(params.toString());

    const updatedFilterItems = { ...activeFilters };

    if (!updatedFilterItems[category]) {
      updatedFilterItems[category] = [];
    }

    const index = updatedFilterItems[category].indexOf(item);
    // Toggle the item in the array
    if (index === -1) {
      // Item not found, add it to the array
      updatedFilterItems[category].push(item);
      try {
        // Send to backend
        axios.get("/materials/filter", {
          params: {
            filterType: category,
            filterValue: item,
            filterText: categories[category].find((c) => c.key == item).value,
            language: getLanguageKey(),
          },
        });
      } catch (error) {}
    } else {
      // Item found, remove it from the array
      updatedFilterItems[category].splice(index, 1);
      if (updatedFilterItems[category].length === 0) delete updatedFilterItems[category];
    }
    // Update the searchParams
    setActiveFilters(updatedFilterItems);

    console.log(updatedFilterItems);
  };

  useEffect(() => {
    if (categories) {
      // Initialize itemsToShow with maxItemsToShow for each category
      const initialItemsToShow = {};
      Object.keys(categories).forEach((categoryName) => {
        initialItemsToShow[categoryName] = maxItemsToShow;
      });
      setItemsToShow(initialItemsToShow);
    }
  }, [categories]);

  // Toggle the number of items to show for a category
  const toggleItemsToShow = (categoryName, items) => {
    setItemsToShow((prevState) => ({
      ...prevState,
      [categoryName]: prevState[categoryName] === maxItemsToShow ? items.length : maxItemsToShow,
    }));
  };

  const filteredResults = (categoryName, item) => {
    const keys = ["series", "use", "typeOfDocument", "category"];

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let result = false;
      if (categoryName != key) {
        const values = activeFilters[key];
        if (values?.length > 0) {
          result = allMaterials.filter((material) => material[categoryName].value == item.value && values.includes(material[key].key)).length == 0;
        }
      }
      if (result) return true;
    }

    return false;
  };

  const renderCategoryItems = (categoryName, items) => {
    const numItemsToShow = itemsToShow[categoryName] || maxItemsToShow;

    return (
      <>
        {items.length > 0 &&
          items
            .slice(0, numItemsToShow)
            .filter((item) => filteredResults(categoryName, item) == false)
            .map((item, itemIndex) => {
              return (
                <label className="w-full flex items-center my-2" key={`${categoryName}-${itemIndex}`}>
                  <input
                    className=" mr-2 accent-black peer relative appearance-none shrink-0 w-5 h-5 border-2 border-black rounded-md ml-1 bg-white focus:outline-none focus:ring-offset-0 focus:ring-1 focus:ring-blue-100 checked:bg-white checked:border-black"
                    type="checkbox"
                    checked={activeFilters[categoryName]?.includes(item.key) || false}
                    onChange={() => handleCheckboxChange(categoryName, item.key)}
                  />
                  <svg
                    className="absolute p-1  w-5 h-5 pointer-events-none hidden peer-checked:block stroke-black ml-1  outline-none"
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 7.64331L1.41 9L8 2.70382L10.58 5.15924L12 3.8121L8 7.37351e-08L0 7.64331Z" fill="black" />
                  </svg>
                  <p className="font-inter leading-[18px] text-[14px] font-normal">{item.value}</p>
                </label>
              );
            })}
        {items.length > maxItemsToShow && (
          <p className="text-[14px] cursor-pointer font-inter text-black underline leading-[18px] font-semibold mt-[8px]" onClick={() => toggleItemsToShow(categoryName, items)}>
            {numItemsToShow === maxItemsToShow ? (
              <span className="ml-2">
                {" "}
                + <T keyName="FilterDesktop.p.show-more" />
              </span>
            ) : (
              <span className="ml-2">
                - <T keyName="FilterDesktop.p.show-less" />
              </span>
            )}
          </p>
        )}
      </>
    );
  };

  return (
    <div>
      {categories && (
        <>
          <div className="flex justify-between items-center  ">
            <p className="font-roboto leading-[28px] font-medium text-[24px]">
              <T keyName="filter-title" />
            </p>

            {/* {!Object.values(activeFilters).every((arr) => arr.length === 0) &&
              <p className="underline decoration-1 underline-offset-1 cursor-pointer leading-[18px] text-[14px] font-semibold font-roboto"
                onClick={() => setActiveFilters(generateInitialFilterState())}>
                <T keyName="filter-clear" />
              </p>
            } */}
          </div>

          <ul className="flex flex-wrap gap-[6px] mt-[14px] ">
            {Object.keys(activeFilters).map((category) =>
              activeFilters[category].map((item) => (
                <li key={item} onClick={() => handleCheckboxChange(category, item)} className="bg-red-sp rounded-md p-1 flex-col cursor-pointer text-white">
                  <div className="p-1 flex items-center">
                    <p className="inline leading-[18px] text-[14px] font-inter font-light">{categories[category].find((c) => c.key === item).value}</p>
                    <img src={clear} alt="remove this filter item" className="w-[12px] h-[12px] inline ml-[8px]" />
                  </div>
                </li>
              ))
            )}
          </ul>

          <div className="flex flex-col gap-[6px] pt-4 select-none">
            {Object.keys(categories).map((category, index) => (
              <div key={index}>
                <hr />
                <details className="[&_svg]:open:-rotate-180 [&_svg]:open:text-black [&_h3]:open:text-black text-black mt-[20px] mb-[15px]">
                  <summary className="flex cursor-pointer list-none items-center justify-between gap-4 [&::-webkit-details-marker]:hidden">
                    <h3 className="leading-[28px] text-[18px] font-inter font-semibold ">
                      <T keyName={category} />
                    </h3>
                    <svg
                      className="rotate-0 transform"
                      fill="none"
                      height="20"
                      width="20"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                    >
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </summary>
                  <div className="flex flex-col text-black  mt-2">{renderCategoryItems(category, categories[category])}</div>
                </details>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default FilterDesktop;
