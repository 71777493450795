import { parseMutationArgs, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import axios from "../axios";
import { useParams, useSearchParams } from "react-router-dom";
import { useTolgee } from "@tolgee/react";
import useAppState from "../store/appState";

export function useFilter() {
  const [searchWord, setSearchWord] = useState();
  const [company, setCompany] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [materials, setMaterials] = useState();
  const [activeFilters, setActiveFilters] = useState({});
  const [categories, setCategories] = useState();
  const [sortby, setSortby] = useState();
  const [companies, setCompanies] = useState();
  const [defaultCountry, setDefaultCountry] = useState();

  const { supportedLanguages } = useAppState();

  const { seriesId, companyId } = useParams();
  const tolgee = useTolgee(["language"]);

  const languageQuery = searchParams.get("lang");

  const mockMaterialsData = {
    items: [
      {
        key: 1,
        category: { key: 1, value: "Category 1" },
        series: { key: 1, value: "Series 1" },
        use: { key: 1, value: "Use 1" },
        typeOfDocument: { key: 1, value: "Document 1" },
        productCodeNumber: 123,
        lifetimeDownloads: 100,
        lastModified: "2023-01-01T00:00:00Z",
        imageURL: "/public/extensions/3d.png",
        productName: "DIRECT URL! SILENT-DUAL-Instr_Mounting2",
        isDirectUrl: true,
        downloadURL: "www.google.com",
        sizeMB: 0.0,
      },
      {
        key: 2,
        category: { key: 2, value: "Category 2" },
        series: { key: 2, value: "Series 2" },
        use: { key: 2, value: "Use 2" },
        typeOfDocument: { key: 2, value: "Document 2" },
        productCodeNumber: 124,
        lifetimeDownloads: 150,
        lastModified: "2023-02-01T00:00:00Z",
        sizeMB: 200000.2,
        imageURL: "/public/extensions/3d.png",
        productName: "SILENT-DUAL-PDF",
      },
      {
        key: 2,
        category: { key: 2, value: "Category 2" },
        series: { key: 2, value: "Series 2" },
        use: { key: 2, value: "Use 2" },
        typeOfDocument: { key: 2, value: "Document 2" },
        productCodeNumber: 124,
        lifetimeDownloads: 150,
        lastModified: "2023-02-01T00:00:00Z",
        sizeMB: 200000.2,
        imageURL: "/public/extensions/3d.png",
        productName: "SILENT-DUAL-PDF",
      },
      {
        key: 2,
        category: { key: 2, value: "Category 2" },
        series: { key: 2, value: "Series 2" },
        use: { key: 2, value: "Use 2" },
        typeOfDocument: { key: 2, value: "Document 2" },
        productCodeNumber: 124,
        lifetimeDownloads: 150,
        lastModified: "2023-02-01T00:00:00Z",
        sizeMB: 200000.2,
        imageURL: "/public/extensions/3d.png",
        productName: "SILENT-DUAL-PDF",
      },
      {
        key: 2,
        category: { key: 2, value: "Category 2" },
        series: { key: 2, value: "Series 2" },
        use: { key: 2, value: "Use 2" },
        typeOfDocument: { key: 2, value: "Document 2" },
        productCodeNumber: 124,
        lifetimeDownloads: 150,
        lastModified: "2023-02-01T00:00:00Z",
        sizeMB: 200000.2,
        imageURL: "/public/extensions/3d.png",
        productName: "SILENT-DUAL-PDF",
      },
      {
        key: 1,
        category: { key: 1, value: "Category 1" },
        series: { key: 1, value: "Series 1" },
        use: { key: 1, value: "Use 1" },
        typeOfDocument: { key: 1, value: "Document 1" },
        productCodeNumber: 123,
        lifetimeDownloads: 100,
        lastModified: "2023-01-01T00:00:00Z",
        sizeMB: 10000000,
        imageURL: "/public/extensions/3d.png",
        productName: "SILENT-DUAL-Instr_Mounting2",
      },
    ],
    total: 2,
  };

  useEffect(() => {
    setSearchWord(searchParams.get("searchTerm"));
    setCompany(searchParams.get("companyId"));
  }, [searchParams, languageQuery]);

  function getLanguageKey() {
    var languageCode = tolgee.getLanguage();
    return supportedLanguages.find((l) => l.languageCode == languageCode)?.languageNumber ?? 0;
  }

  useEffect(() => {
    if (!companies && localStorage["disd"]) {
      async function getData() {
        const response = await axios.get("/materials/companies", {
          headers: {
            disd: localStorage["disd"],
          },
        });

        setCompanies(response.data);
      }

      getData();
    }
  }, []);

  const { data, isFetching } = useQuery(
    ["search", searchWord, company, seriesId, languageQuery], // Add languageQuery to the dependencies
    async () => {
      const backendIsDown = false;

      if (backendIsDown) {
        return new Promise((resolve) => {
          setTimeout(() => resolve(mockMaterialsData), 1);
        });
      } else {
        const response = await axios.get("/materials", {
          params: {
            filter: {
              searchTerm: searchWord,
              language: languageQuery ? Number(languageQuery) : getLanguageKey(), // Include language key
              seriesId: seriesId,
              companyId: company || companyId,
            },
            page: { pageNumber: 1, pageSize: 9999 },
          },
          headers: {
            disd: localStorage["disd"],
          },
        });

        setDefaultCountry(response.data?.meta);

        return response.data;
      }
    },
    {
      select: (data) => {
        if (data?.total === 0) {
          return [];
        } else {
          return data?.items;
        }
      },
      enabled: seriesId !== undefined || (searchWord !== undefined && searchWord !== null && searchWord !== ""),
    }
  );

  useEffect(() => {
    setMaterials(null);
    setActiveFilters({});

    if (!data) {
      return;
    }

    setMaterials(filterMaterials(data));

    const productCategories = removeDuplicates(data.map((m) => m.category) ?? []);
    const series = removeDuplicates(data.map((m) => m.series) ?? []);

    // const uses = removeDuplicatesAndAddSeries(data, "use");
    // const typeOfDocuments = removeDuplicatesAndAddSeries(data, "typeOfDocument");

    const uses = removeDuplicates(data.map((m) => m.use) ?? []);
    const typeOfDocuments = removeDuplicates(data.map((m) => m.typeOfDocument) ?? []);

    setCategories({ category: productCategories, series, use: uses, typeOfDocument: typeOfDocuments });
  }, [data]);

  useEffect(() => {
    if (data) setMaterials(filterMaterials(data));
  }, [activeFilters, sortby]);

  function removeDuplicatesAndAddSeries(data, propertyName) {
    const categoryWithSeries = {};

    data.forEach((item) => {
      const categoryType = item[propertyName].key;
      const series = item.series;

      if (!categoryWithSeries[categoryType]) {
        categoryWithSeries[categoryType] = {};
        categoryWithSeries[categoryType].series = [];
        categoryWithSeries[categoryType].value = item.typeOfDocument.value;
      }

      if (!categoryWithSeries[categoryType].series.some((s) => s.key == series.key)) {
        categoryWithSeries[categoryType].series.push(series);
      }
    });

    return Object.entries(categoryWithSeries).map(([key, value]) => ({
      key: parseInt(key),
      value: value.value,
      series: value.series,
    }));
  }

  function removeDuplicates(arr) {
    let unique = arr.reduce(function (acc, curr) {
      if (!acc.some((a) => a.key == curr.key)) acc.push(curr);
      return acc;
    }, []);
    return unique;
  }

  const filterMaterials = (data) => {
    const filteredResults = data.filter((material) => {
      const matches = Object.entries(activeFilters).every(([category, selectedItems]) => {
        return selectedItems.length === 0 || selectedItems.includes(material[category].key);
      });
      return matches;
    });

    if (sortby === "recommended") {
      // don't know what 'recommended' is based on, so just using the highest product number
      filteredResults.sort((a, b) => b.productCodeNumber - a.productCodeNumber);
    }
    if (sortby === "popular") {
      filteredResults.sort((a, b) => b.lifetimeDownloads - a.lifetimeDownloads);
    }
    if (sortby === "newest") {
      filteredResults.sort((a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime());
    }

    return filteredResults;
  };

  return {
    searchWord,
    setSearchWord,
    materials,
    activeFilters,
    setActiveFilters,
    sortby,
    setSortby,
    categories,
    isFetching,
    companies,
    data,
    defaultCountry,
  };
}
