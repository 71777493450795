import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useTolgee, useTranslate } from "@tolgee/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useAppState from "../store/appState";
import { server } from "../AppSetting";
import fallbackImage from "../assets/images/resultsPlaceholder.png";
import loadingImage from "../assets/images/test-loading.png";
import { T } from "@tolgee/react";
import axios from "../axios";
import { DownloadIcon, CopyIcon, QRIcon, ViewIcon, ShareIcon } from "../assets/icons";
import { useForm } from "react-hook-form";
import copy from "copy-to-clipboard";

function ResultsCard({ material, defaultCountry }) {
  let host = server;
  if (host == "") host = window.location.origin;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isMobile, scrollbarWidth } = useAppState();
  const { t } = useTranslate();
  const [showCopied, setShowCopied] = useState(false);

  const encodedMaterialURL = encodeURIComponent(material.downloadURL);
  const materialDownloadURL = material.isDirectUrl ? material.downloadURL : `${host}/api/materials/get?filePath=${encodedMaterialURL}`;
  const materialImageURL = material.useImageStore ? `${host}/api/materials/preview?filePath=${material.imageURL}` : material.imageURL;

  const [showQRModal, setShowQRModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);

  const { supportedLanguages } = useAppState();

  const hasConsented = () => {
    const consent = JSON.parse(localStorage.getItem("consent") ?? null);
    return consent != null && consent.email;
  };

  const getMaterialDownloadUrl = async () => {
    checkConsent(() => {
      window.location.href = materialDownloadURL;
    });
  };

  const checkConsent = async (callback) => {
    if (material.requireConsent) {
      const consent = JSON.parse(localStorage.getItem("consent") ?? null);
      if (consent == null || !consent.email) {
        setShowFormModal(true);
      } else {
        await submitConsent(callback, {
          email: consent.email,
          consentToMarketing: consent.consentMarketing,
        });
      }
    } else {
      callback();
    }
  };

  const onSubmit = (data) => {
    submitConsent(() => {
      window.location.href = materialDownloadURL;
    }, data);
  };

  const submitConsent = async (callback, formData) => {
    try {
      const formDetails = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        country: formData.country,
        professionalActivity: formData.profession,
        consentToMarketing: formData.consentMarketing,
        media: material.downloadURL,
      };
      await axios.post(`/materials/consent`, formDetails);
      localStorage.setItem("consent", JSON.stringify(formDetails));
      callback();
      setShowFormModal(false);
    } catch (error) {}
  };

  const tolgee = useTolgee(["language"]);

  function getLanguageKey() {
    var languageCode = tolgee.getLanguage();
    return supportedLanguages.find((l) => l.languageCode == languageCode)?.languageNumber ?? 0;
  }

  const handleOpenQRModal = () => {
    checkConsent(() => {
      document.body.classList.add("no-scrollbar-shift");
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      toggleQRCode(true);
    });
  };

  const handleOpenPreviewModal = () => {
    document.body.classList.add("no-scrollbar-shift");
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    setShowPreviewModal(true);
    updateBackend("preview", materialDownloadURL);
  };

  useEffect(() => {
    if (showQRModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
      document.body.style.paddingRight = 0;
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showQRModal]);

  useEffect(() => {
    if (showPreviewModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
      document.body.style.paddingRight = 0;
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showPreviewModal]);

  useEffect(() => {
    if (showFormModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
      document.body.style.paddingRight = 0;
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showFormModal]);

  const toggleQRCode = (state) => {
    setShowQRModal(state);
    if (state) {
      updateBackend("qr", materialDownloadURL);
    }
  };

  const handleCopyToClipboard = () => {
    checkConsent(() => {
      copy(materialDownloadURL, { message: null });
      setShowCopied(true);
      updateBackend("copyLink", materialDownloadURL);
    });
  };

  function formatBytes(bytes, decimals) {
    if (bytes == 0) return "";
    var k = 1024,
      dm = decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  function updateBackend(downloadType, fileName) {
    try {
      // Send to backend
      axios.post(`/materials/download/${downloadType}/${getLanguageKey()}`, {
        assetName: fileName,
        category: material.category,
        use: material.use,
        typeOfDocument: material.typeOfDocument,
        series: material.series,
      });
    } catch (error) {}
  }

  function shareContent(materialDownloadURL) {
    checkConsent(() => {
      if (navigator.share) {
        navigator
          .share({
            title: material.productName,
            text: `${t("ResultsCard.share-text")}`,

            url: materialDownloadURL,
          })
          .then(() => updateBackend("shareLink", materialDownloadURL))
          /*.then(() => console.log(`Share complete for ${materialDownloadURL}`))*/
          .catch((error) => console.error("Could not share at this time", error));
      } else {
        console.error("Web Share API is not supported in your browser.");
      }
    });
  }

  if (isMobile) {
    return (
      <>
        <li className="font-roboto w-[342px] h-[150px] flex flex-row select-none ">
          <div
            // cursor becomes pointer & onClick added ONLY if preview is available
            className={` w-[108px] h-full  ${!materialImageURL.includes("extensions") ? "cursor-pointer" : ""}`}
            onClick={!materialImageURL.includes("extensions") ? () => handleOpenPreviewModal() : undefined}
          >
            <ImageWithFallback
              src={materialImageURL}
              fallbackSrc={fallbackImage}
              alt={material.imageURL}
              //className={`w-full h-full object-cover object-left-top`}
            />
          </div>

          <div className="flex flex-col w-[242px] justify-center pl-5  ">
            <div className="flex flex-col mb-3 ">
              <p className=" text-[18px] leading-6 font-semibold line-clamp-2 mb-1 ">{material.productName}</p>
              {material.sizeMB > 0 && <p className="leading-4 text-grey-5 text-[14px] font-inter">{formatBytes(material.sizeMB)}</p>}
            </div>

            <div className="flex flex-row flex-wrap gap-x-5 underline text-dark-grey gap-1">
              {/* render different icon and label dependant on if it's a directURL  */}
              {material.isDirectUrl ? (
                /* view */
                <a
                  className="flex flex-row gap-0.5 py-[8px] pr-[7px] pl-[1px] rounded-md items-center   hover:bg-grey-3-sp active:bg-grey-2"
                  //href={materialDownloadURL}
                  onClick={() => {
                    getMaterialDownloadUrl();
                    updateBackend("download", materialDownloadURL);
                  }} // treating a 'view' the same as a 'download' with regard to metrics
                  target="_blank"
                >
                  <img src={ViewIcon} className="w-[18px] h-[18px]" alt="" />
                  <div className="font-semibold leading-[18px] text-sm"> {t("ResultsCard.view")}</div>
                </a>
              ) : (
                /* download */
                <a
                  className="flex flex-row gap-0.5 py-[8px] pr-[7px] pl-[1px] rounded-md items-center   hover:bg-grey-3-sp active:bg-grey-2"
                  //href={materialDownloadURL}
                  onClick={() => {
                    getMaterialDownloadUrl();
                    updateBackend("download", materialDownloadURL);
                  }}
                  download
                >
                  <img src={DownloadIcon} className="w-[18px] h-[18px]" alt="" />
                  <div className="font-semibold leading-[18px] text-sm"> {t("ResultsCard.download")}</div>
                </a>
              )}

              {/* share */}
              <button
                className="flex flex-row gap-0.5 py-[8px] pr-[7px] pl-[4px] rounded-md items-center hover:bg-grey-3-sp active:bg-grey-2"
                onClick={() => shareContent(materialDownloadURL, material.productName)}
              >
                <img src={ShareIcon} className="w-[18px] h-[18px]" alt="" />
                <div className="font-semibold text-sm"> {t("ResultsCard.share")}</div>
              </button>

              {/* show qr modal */}
              <button
                className="hidden md:flex flex-row gap-0.5 py-[8px] pr-[7px] pl-[1px] rounded-md items-center hover:bg-grey-3-sp active:bg-grey-2"
                onClick={() => handleOpenQRModal()}
              >
                <img src={QRIcon} className="w-[18px] h-[18px]" alt="" />
                <div className="font-semibold text-sm">{material.isDirectUrl ? t("ResultsCard.view-on-mobile") : t("ResultsCard.download-on-mobile")}</div>
              </button>
            </div>
          </div>
        </li>

        {/* mobile qr modal */}
        {showQRModal && (
          <div className="z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center p-6" onClick={() => toggleQRCode(false)}>
            <div className="bg-white rounded-lg p-6" onClick={(e) => e.stopPropagation()}>
              <div className="flex justify-end">
                <svg className="cursor-pointer" onClick={() => toggleQRCode(false)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="flex flex-col justify-center p-8 gap-x-8 ">
                <div className=" flex flex-col align-middle  items-center w-full max-w-72 ">
                  <p className="text-center pb-6 font-inter font-semibold">{material.isDirectUrl ? <T keyName="qr-modal-text-view" /> : <T keyName="qr-modal-text-download" />}</p>
                  <QRCode value={materialDownloadURL} size={128} style={{ height: "100%", width: "100%" }} viewBox={`0 0 256 256`} bgColor={"white"} />
                </div>
              </div>
            </div>
          </div>
        )}

        {showFormModal && (
          <div className="z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center" onClick={() => setShowFormModal(false)}>
            <div className="bg-white rounded-lg p-4" onClick={(e) => e.stopPropagation()}>
              <div className="flex justify-end">
                <div className="sm:mx-auto sm:w-full sm:max-w-[580px]">
                  <div className="bg-white px-6 py-12 sm:px-12">
                    <div>
                      <ConsentForm register={register} handleSubmit={handleSubmit} onSubmit={onSubmit} errors={errors} t={t} defaultCountry={defaultCountry} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* mobile preview modal */}
        {showPreviewModal && (
          <div className="z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center p-4" onClick={() => setShowPreviewModal(false)}>
            <div className="bg-white rounded-lg w-full h-full p-6" onClick={(e) => e.stopPropagation()}>
              <div className="flex justify-end">
                <svg
                  className="cursor-pointer"
                  onClick={() => setShowPreviewModal(false)}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="flex justify-center p-4 h-full">
                <img src={materialImageURL} alt={material.imageURL} className=" object-contain" />
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        <li className="flex flex-col font-roboto w-[185px] h-[372px] ">
          <div
            // cursor becomes pointer & onClick added ONLY if preview is available
            className={` w-full  h-[264px]  ${!materialImageURL.includes("extensions") ? "cursor-pointer" : ""}`}
            onClick={!materialImageURL.includes("extensions") ? () => handleOpenPreviewModal() : undefined}
          >
            <ImageWithFallback
              src={materialImageURL}
              fallbackSrc={fallbackImage}
              alt={material.imageURL}
              //className={`w-full h-full object-cover object-left-top`}
            />
          </div>

          <div className=" mt-[10px] mb-[9px]">
            <p className=" text-[16px] leading-5 font-semibold line-clamp-2 mb-1">
              {material.productName}
              {/* Some File.pdf */}
            </p>

            {material.sizeMB > 0 && <p className=" text-[14px] leading-4 text-grey-5 font-inter font-normal">{formatBytes(material.sizeMB)}</p>}
          </div>

          <div className="flex flex-row gap-5 mt-auto">
            {/* render different icon and label dependant on if it's a directURL  */}
            {material.isDirectUrl ? (
              /* visualise */
              <div className="tooltip hover:bg-grey-3-sp active:bg-grey-2 rounded-md transition-all duration-300	">
                <a
                  className="flex flex-row items-center text-grey-2-sp"
                  onClick={() => {
                    getMaterialDownloadUrl();
                    updateBackend("download", materialDownloadURL);
                  }}
                  target="_blank"
                >
                  <img src={ViewIcon} className="p-[2px]" alt="" />
                </a>
                <span className="tooltiptext">{t("ResultsCard.view")}</span>
              </div>
            ) : (
              /* download */

              <div className="tooltip hover:bg-grey-3-sp active:bg-grey-2 rounded-md transition-all duration-300">
                <a
                  className="flex flex-row items-center text-grey-2-sp"
                  onClick={() => {
                    getMaterialDownloadUrl();
                    updateBackend("download", materialDownloadURL);
                  }}
                  download
                >
                  <img src={DownloadIcon} className="p-[2px]" alt="" />
                </a>
                <span className="tooltiptext">{t("ResultsCard.download")}</span>
              </div>
            )}

            {/* copy to clipboard */}
            <button
              className="tooltip cursor-pointer hover:bg-grey-3-sp active:bg-grey-2 rounded-md transition-all duration-300"
              onClick={handleCopyToClipboard}
              onMouseLeave={() => setShowCopied(false)}
            >
              {/* <CopyToClipboard text={materialDownloadURL}> */}
              <div className="flex flex-row items-center  	   text-grey-2-sp">
                <img src={CopyIcon} className="p-[2px]" alt="" />
              </div>
              {/* </CopyToClipboard> */}
              <span className="tooltiptext">{showCopied ? t("ResultsCard.link-copied") : t("ResultsCard.copy-link")}</span>
            </button>

            {/* show qr code modal */}
            <button className="tooltip hover:bg-grey-3-sp active:bg-grey-2 rounded-md transition-all duration-300 " onClick={() => handleOpenQRModal()}>
              <img src={QRIcon} className="p-[2px]" alt="" />
              <span className="tooltiptext">{material.isDirectUrl ? t("ResultsCard.view-on-mobile") : t("ResultsCard.download-on-mobile")}</span>
            </button>
          </div>
        </li>

        {/* desktop qr modal */}
        {showQRModal && (
          <div className="z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center" onClick={() => toggleQRCode(false)}>
            <div className="bg-white rounded-lg p-4" onClick={(e) => e.stopPropagation()}>
              <div className="flex justify-end">
                <svg className="cursor-pointer" onClick={() => toggleQRCode(false)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="flex flex-row justify-center p-8 gap-x-24 ">
                <div className=" w-[185px]">
                  <div className=" box-content   border-solid border border-grey-2 flex justify-center items-center w-[108px] lg:w-full h-full lg:h-[264px]">
                    <img
                      src={materialImageURL}
                      alt={material.imageURL}
                      //className={`w-full h-full object-cover object-left-top`}
                    />
                  </div>
                  <div className=" mt-[10px] mb-[9px]">
                    <p className=" text-[16px] leading-5 font-semibold line-clamp-2 mb-1">{material.productName}</p>

                    {material.sizeMB > 0 && <p className=" text-[14px] leading-4 text-grey-5 font-inter font-normal	 ">{formatBytes(material.sizeMB)}</p>}
                  </div>
                </div>
                <div className=" flex flex-col align-middle  items-center w-80 ">
                  <p className="text-center pb-6 font-semibold font-inter">{material.isDirectUrl ? <T keyName="qr-modal-text-view" /> : <T keyName="qr-modal-text-download" />}</p>
                  <QRCode value={materialDownloadURL} size={128} style={{ height: "75%", width: "75%" }} viewBox={`0 0 256 256`} bgColor={"white"} />
                </div>
              </div>
            </div>
          </div>
        )}

        {showFormModal && (
          <div className="z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center" onClick={() => setShowFormModal(false)}>
            <div className="bg-white rounded-lg p-4" onClick={(e) => e.stopPropagation()}>
              <div className="flex justify-end">
                <div className="sm:mx-auto sm:w-full sm:max-w-[580px]">
                  <div className="bg-white px-6 py-12 sm:px-12">
                    <div>
                      <ConsentForm register={register} handleSubmit={handleSubmit} onSubmit={onSubmit} errors={errors} t={t} defaultCountry={defaultCountry} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* desktop preview modal */}
        {showPreviewModal && (
          <div className="z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center" onClick={() => setShowPreviewModal(false)}>
            <div className="bg-white rounded-lg p-4" onClick={(e) => e.stopPropagation()}>
              <div className="flex justify-end">
                <svg
                  className="cursor-pointer"
                  onClick={() => setShowPreviewModal(false)}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="flex flex-row justify-center p-8 gap-x-8">
                <img src={materialImageURL} alt={material.imageURL} className="h-auto" style={{ maxHeight: "80vh", maxWidth: "100%", justifyContent: "center" }} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const ConsentForm = ({ register, handleSubmit, onSubmit, errors, t, defaultCountry }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            {t("ConsentForm.FirstName")}
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="firstName"
              className={`block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
                errors.firstName ? "ring-red-300 placeholder:text-red-300 focus:ring-red-500" : ""
              }`}
              //placeholder={t("ConsentForm.FirstName")}
              {...register("firstName", { required: true, maxLength: 80 })}
            />
          </div>
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            {t("ConsentForm.LastName")}
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="lastName"
              className={`block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
                errors.lastName ? "ring-red-300 placeholder:text-red-300 focus:ring-red-500" : ""
              }`}
              //placeholder={t("ConsentForm.LastName")}
              {...register("lastName", { required: true, maxLength: 80 })}
            />
          </div>
        </div>
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
          {t("ConsentForm.Email")}
        </label>
        <div className="mt-2">
          <input
            type="text"
            id="email"
            className={`block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
              errors.email ? "ring-red-300 placeholder:text-red-300 focus:ring-red-500" : ""
            }`}
            //placeholder={t("ConsentForm.Email")}
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
          />
        </div>
      </div>

      <div>
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
          {t("ConsentForm.Country")}
        </label>
        <select
          id="country"
          name="country"
          defaultValue={defaultCountry}
          {...register("country", { required: true })}
          className={`mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 sm:text-sm sm:leading-6 ${
            errors.country ? "ring-red-300 placeholder:text-red-300 focus:ring-red-500" : ""
          }`}
        >
          <option value=""></option>
          <option value="Afghanistan">{t("Country.Afghanistan")}</option>
          <option value="Albania">{t("Country.Albania")}</option>
          <option value="Algeria">{t("Country.Algeria")}</option>
          <option value="Angola">{t("Country.Angola")}</option>
          <option value="Argentina">{t("Country.Argentina")}</option>
          <option value="Australia">{t("Country.Australia")}</option>
          <option value="Austria">{t("Country.Austria")}</option>
          <option value="Bangladesh">{t("Country.Bangladesh")}</option>
          <option value="Belarus">{t("Country.Belarus")}</option>
          <option value="Belgium">{t("Country.Belgium")}</option>
          <option value="Belize">{t("Country.Belize")}</option>
          <option value="Benin">{t("Country.Benin")}</option>
          <option value="Bolivia">{t("Country.Bolivia")}</option>
          <option value="Bosnia Herzegovina">{t("Country.BosniaHerzegovina")}</option>
          <option value="Brazil">{t("Country.Brazil")}</option>
          <option value="Bulgaria">{t("Country.Bulgaria")}</option>
          <option value="Cambodia">{t("Country.Cambodia")}</option>
          <option value="Cameroon">{t("Country.Cameroon")}</option>
          <option value="Canada">{t("Country.Canada")}</option>
          <option value="Chile">{t("Country.Chile")}</option>
          <option value="China">{t("Country.China")}</option>
          <option value="Colombia">{t("Country.Colombia")}</option>
          <option value="Congo">{t("Country.Congo")}</option>
          <option value="Costa Rica">{t("Country.CostaRica")}</option>
          <option value="Croatia">{t("Country.Croatia")}</option>
          <option value="Cuba">{t("Country.Cuba")}</option>
          <option value="Cyprus">{t("Country.Cyprus")}</option>
          <option value="Czech Republic">{t("Country.CzechRepublic")}</option>
          <option value="Denmark">{t("Country.Denmark")}</option>
          <option value="Dominican Republic">{t("Country.DominicanRepublic")}</option>
          <option value="Ecuador">{t("Country.Ecuador")}</option>
          <option value="Egypt">{t("Country.Egypt")}</option>
          <option value="El Salvador">{t("Country.ElSalvador")}</option>
          <option value="Estonia">{t("Country.Estonia")}</option>
          <option value="Ethiopia">{t("Country.Ethiopia")}</option>
          <option value="Fiji">{t("Country.Fiji")}</option>
          <option value="Finland">{t("Country.Finland")}</option>
          <option value="France">{t("Country.France")}</option>
          <option value="Gabon">{t("Country.Gabon")}</option>
          <option value="Georgia">{t("Country.Georgia")}</option>
          <option value="Germany">{t("Country.Germany")}</option>
          <option value="Ghana">{t("Country.Ghana")}</option>
          <option value="Greece">{t("Country.Greece")}</option>
          <option value="Guatemala">{t("Country.Guatemala")}</option>
          <option value="Haiti">{t("Country.Haiti")}</option>
          <option value="Honduras">{t("Country.Honduras")}</option>
          <option value="Hungary">{t("Country.Hungary")}</option>
          <option value="India">{t("Country.India")}</option>
          <option value="Indonesia">{t("Country.Indonesia")}</option>
          <option value="Iran">{t("Country.Iran")}</option>
          <option value="Iraq">{t("Country.Iraq")}</option>
          <option value="Ireland">{t("Country.Ireland")}</option>
          <option value="Israel">{t("Country.Israel")}</option>
          <option value="Italy">{t("Country.Italy")}</option>
          <option value="Jamaica">{t("Country.Jamaica")}</option>
          <option value="Japan">{t("Country.Japan")}</option>
          <option value="Jordan">{t("Country.Jordan")}</option>
          <option value="Kazakhstan">{t("Country.Kazakhstan")}</option>
          <option value="Kenya">{t("Country.Kenya")}</option>
          <option value="South Korea">{t("Country.KoreaSouth")}</option>
          <option value="Kuwait">{t("Country.Kuwait")}</option>
          <option value="Kyrgyzstan">{t("Country.Kyrgyzstan")}</option>
          <option value="Laos">{t("Country.Laos")}</option>
          <option value="Latvia">{t("Country.Latvia")}</option>
          <option value="Lebanon">{t("Country.Lebanon")}</option>
          <option value="Libya">{t("Country.Libya")}</option>
          <option value="Lithuania">{t("Country.Lithuania")}</option>
          <option value="Luxembourg">{t("Country.Luxembourg")}</option>
          <option value="Madagascar">{t("Country.Madagascar")}</option>
          <option value="Malaysia">{t("Country.Malaysia")}</option>
          <option value="Mali">{t("Country.Mali")}</option>
          <option value="Malta">{t("Country.Malta")}</option>
          <option value="Mexico">{t("Country.Mexico")}</option>
          <option value="Moldova">{t("Country.Moldova")}</option>
          <option value="Mongolia">{t("Country.Mongolia")}</option>
          <option value="Morocco">{t("Country.Morocco")}</option>
          <option value="Mozambique">{t("Country.Mozambique")}</option>
          <option value="Myanmar">{t("Country.Myanmar")}</option>
          <option value="Nepal">{t("Country.Nepal")}</option>
          <option value="Netherlands">{t("Country.Netherlands")}</option>
          <option value="New Zealand">{t("Country.NewZealand")}</option>
          <option value="Nicaragua">{t("Country.Nicaragua")}</option>
          <option value="Nigeria">{t("Country.Nigeria")}</option>
          <option value="Norway">{t("Country.Norway")}</option>
          <option value="Pakistan">{t("Country.Pakistan")}</option>
          <option value="Panama">{t("Country.Panama")}</option>
          <option value="Papua New Guinea">{t("Country.PapuaNewGuinea")}</option>
          <option value="Paraguay">{t("Country.Paraguay")}</option>
          <option value="Peru">{t("Country.Peru")}</option>
          <option value="Philippines">{t("Country.Philippines")}</option>
          <option value="Poland">{t("Country.Poland")}</option>
          <option value="Portugal">{t("Country.Portugal")}</option>
          <option value="Qatar">{t("Country.Qatar")}</option>
          <option value="Romania">{t("Country.Romania")}</option>
          <option value="Russia">{t("Country.Russia")}</option>
          <option value="Saudi Arabia">{t("Country.SaudiArabia")}</option>
          <option value="Senegal">{t("Country.Senegal")}</option>
          <option value="Serbia">{t("Country.Serbia")}</option>
          <option value="Singapore">{t("Country.Singapore")}</option>
          <option value="Slovakia">{t("Country.Slovakia")}</option>
          <option value="Slovenia">{t("Country.Slovenia")}</option>
          <option value="South Africa">{t("Country.SouthAfrica")}</option>
          <option value="Spain">{t("Country.Spain")}</option>
          <option value="Sri Lanka">{t("Country.SriLanka")}</option>
          <option value="Sudan">{t("Country.Sudan")}</option>
          <option value="Sweden">{t("Country.Sweden")}</option>
          <option value="Switzerland">{t("Country.Switzerland")}</option>
          <option value="Syria">{t("Country.Syria")}</option>
          <option value="Taiwan">{t("Country.Taiwan")}</option>
          <option value="Tanzania">{t("Country.Tanzania")}</option>
          <option value="Thailand">{t("Country.Thailand")}</option>
          <option value="Turkey">{t("Country.Turkey")}</option>
          <option value="Ukraine">{t("Country.Ukraine")}</option>
          <option value="United Arab Emirates">{t("Country.UnitedArabEmirates")}</option>
          <option value="United Kingdom">{t("Country.UnitedKingdom")}</option>
          <option value="United States">{t("Country.UnitedStates")}</option>
          <option value="Uruguay">{t("Country.Uruguay")}</option>
          <option value="Uzbekistan">{t("Country.Uzbekistan")}</option>
          <option value="Venezuela">{t("Country.Venezuela")}</option>
          <option value="Vietnam">{t("Country.Vietnam")}</option>
          <option value="Zambia">{t("Country.Zambia")}</option>
          <option value="Zimbabwe">{t("Country.Zimbabwe")}</option>
        </select>
      </div>

      <div>
        <label htmlFor="profession" className="block text-sm font-medium leading-6 text-gray-900">
          {t("ConsentForm.Profession")}
        </label>
        <select
          id="profession"
          name="profession"
          defaultValue=""
          {...register("profession", { required: true })}
          className={`mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 sm:text-sm sm:leading-6 ${
            errors.profession ? "ring-red-300 placeholder:text-red-300 focus:ring-red-500" : ""
          }`}
        >
          <option value=""></option>
          <option value="Ingenier�a">{t("Profession.Engineering")}</option>
          <option value="Estudio arquitectura">{t("Profession.Architecture")}</option>
          <option value="Instalador">{t("Profession.Individual")}</option>
          <option value="Particular">{t("Profession.Private")}</option>
          <option value="Mantenimiento">{t("Profession.Maintenance")}</option>
          <option value="Distribuidor">{t("Profession.Distributor")}</option>
          <option value="Promotora">{t("Profession.Promoter")}</option>
          <option value="Constructora">{t("Profession.Contruction")}</option>
          <option value="OEM">{t("Profession.OEM")}</option>
          <option value="Otros">{t("Profession.Other")}</option>
        </select>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="consent"
            name="consent"
            type="checkbox"
            className={`h-4 w-4 rounded border-gray-300 ${errors.consent ? " focus:ring-red-500" : ""}`}
            {...register("consent", { required: true })}
          />
          <label htmlFor="consent" className={`ml-3 block text-sm leading-6  ${errors.consent ? " text-red-500" : "text-gray-900"}`}>
            {t("ConsentForm.Agree")}
          </label>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="consent-marketing"
            name="consent-marketing"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 "
            {...register("consentMarketing", { required: false })}
          />
          <label htmlFor="consent-marketing" className="ml-3 block text-sm leading-6 text-gray-900">
            {t("ConsentForm.AgreeMarketing")}
          </label>
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="flex w-full justify-center rounded-md  px-3 py-1.5 bg-black text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-sp focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {t("ConsentForm.Submit")}
        </button>
      </div>
    </form>
  );
};

const Spinner = () => <img src={loadingImage} alt="loading" className="animate-pulse h-full w-full " />;

const ImageWithFallback = ({ src, fallbackSrc, alt, className, style }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsLoaded(false);
    setImageSrc(null);

    const img = new Image();
    img.src = src;

    img.onload = () => {
      setImageSrc(src);
      setIsLoading(false);

      setTimeout(() => {
        setIsLoaded(true);
      }, 50);
    };

    img.onerror = () => {
      setImageSrc(fallbackSrc);
      setIsLoading(false);

      setTimeout(() => {
        setIsLoaded(true);
      }, 50);
    };
  }, [src, fallbackSrc]);

  return (
    <div className="border-solid border border-grey-2 flex justify-center items-center w-[108px] lg:w-full h-full lg:h-[264px]">
      {isLoading ? (
        <Spinner />
      ) : (
        <img
          src={imageSrc}
          alt={alt}
          className={className}
          style={{
            ...style,
            opacity: isLoaded ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        />
      )}
    </div>
  );
};

export default ResultsCard;
