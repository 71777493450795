import logo from "../assets/images/asset-1a-1@2x.png"
import OpenInNewIcon from "../assets/icons/open_in_new.svg"
import LanguageSelect from "./LanguageSelect"
import { T } from "@tolgee/react"
import { useTranslate } from '@tolgee/react'

function Header() {

    const { t } = useTranslate()

    return (
        <header className="sticky top-0 left-0 right-0 z-50 w-full bg-white h-[72px] lg:h-[100px]">
            <nav className="grid grid-cols-1 grid-rows-2 gap-0 ">
                <div id="top-row" className="bg-grey-1 h-[36px] lg:h-[50px] flex items-center justify-end pr-[20px] lg:pr-[95px]">
                    <LanguageSelect />
                </div>
                <div id="bottom-row" className="bg-black lg:bg-grey-1-sp h-[36px] lg:h-[50px] flex items-center justify-end pr-[20px] lg:pr-[95px]">
                    <div id="links" className="flex-row leading-[24px] text-base font-roboto font-semibold gap-6 lg:gap-10 text-grey-3-sp flex">
                        <a href="https://www.solerpalau.com/" target="_blank" rel="noopener noreferrer" className="flex items-center">
                            <T keyName="Header.a.sandp-link" />
                            &nbsp;
                            <img src={OpenInNewIcon} className="inline-block" alt={t({ key: 'Header.a.sandp-link' })} /></a>
                        <a href="https://easyvent.solerpalau.com/" target="_blank" rel="noopener noreferrer" className="flex items-center">
                            <T keyName="Header.a.easyvent-link" />
                            &nbsp;
                            <img src={OpenInNewIcon} className="inline-block" alt={t({ key: 'Header.a.easyvent-link' })} /></a>
                    </div>
                </div>
            </nav>
            <a href="/" target="_self">
                <img
                    className="absolute top-[17px] ml-[22px] lg:ml-[117px] w-[62px] lg:w-[92px]"
                    alt=""
                    src={logo}
                /></a>
        </header>
    )
}

export default Header;