import { create } from 'zustand';
import zukeeper from 'zukeeper';

const useAppState = create(zukeeper(set => ({

    country: { countryCode: 'pending...', countryName: 'pending...' },
    setCountry: (newCountry) => set({ country: newCountry }),

    supportedCountries: [
        { countryCode: 'GB', countryName: 'United Kingdom' },
        { countryCode: 'ES', countryName: 'España' },
        { countryCode: 'FR', countryName: 'Français' }
    ],

    supportedLanguages: [
        { languageCode: 'es', languageName: 'Español', languageNumber: 0 },
        { languageCode: 'en', languageName: 'English', languageNumber: 1 },
        { languageCode: 'fr', languageName: 'Français', languageNumber: 2 },
    ],

    isMobile: false,
    setIsMobile: (newIsMobile) => set({ isMobile: newIsMobile }),
    displayMobileFilterModal: false,
    setDisplayMobileFilterModal: (newDisplayMobileFilterModal) => set({ displayMobileFilterModal: newDisplayMobileFilterModal }),

    searchResults: [], // these are the results after taking into account the users search string
    setSearchResults: (newSearchResults) => set({ searchResults: newSearchResults }),
    filterResults: [], // these are the results after taking into account the users selected filters
    setFilterResults: (newFilterResults) => set({ filterResults: newFilterResults }),
    sortResults: [], // these are the results after taking into account the users selected sort option
    setSortResults: (newSortResults) => set({ sortResults: newSortResults }),
    // resultsToRender: [], // these are the final results to display
    // setResultsToRender: (newResultsToRender) => set({ resultsToRender: newResultsToRender }),

    scrollbarWidth: 0,
    setScrollbarWidth: (width) => set({ scrollbarWidth: width }),

})));

window.store = useAppState;

export default useAppState;