import ResultsSort from "./ResultsSort";
import ResultsPagination from "./ResultsPagination";

function ResultsDisplay({ materials, sortby, setSortby, activeFilters, defaultCountry }) {
  return (
    <>
      <ResultsSort materials={materials} sortby={sortby} setSortby={setSortby} activeFilters={activeFilters} />
      <ResultsPagination materials={materials} defaultCountry={defaultCountry} />
    </>
  );
}

export default ResultsDisplay;
