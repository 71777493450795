import { useEffect, useState } from "react";
import SearchIcon from "../assets/icons/search.svg";
import ClearIcon from "../assets/icons/cancel.svg";
import { useTranslate } from "@tolgee/react";
import useAppState from "../store/appState";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

function CompanySelection({ companies }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCompany, setSelectedCompany] = useState("");

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    if (companyId) {
      setSelectedCompany(companyId);
    }
  }, [searchParams]);

  const handleChange = (e) => {
    const newCompanyId = e.target.value;
    sessionStorage["companyId"] = newCompanyId;

    const newParams = new URLSearchParams(searchParams);
    newParams.set("companyId", newCompanyId);
    setSearchParams(newParams);

    setSelectedCompany(newCompanyId);
  };

  return (
    <select value={selectedCompany} onChange={handleChange}>
      <option value="">Auto detect</option>
      {companies.map((c) => (
        <option key={c.keycompany} value={c.keycompany}>
          {c.nombreComercial} ({c.idCompany})
        </option>
      ))}
    </select>
  );
}

export default CompanySelection;
