import { T } from '@tolgee/react'

function NotFound404() {
    return (
        <div className="py-40 lg:py-60 text-center flex flex-col gap-4 font-roboto font-semibold bg-white">
            <h1 className="text-7xl">404</h1>
            <p><T keyName="NotFound404.page-not-found" /></p>
            <a href="/" className="underline"><T keyName="NotFound404.return-to-home-page" /></a>
        </div>
    )
}

export default NotFound404