import useAppState from "../store/appState";
import FilterIcon from "../assets/icons/filters_no_mat.svg";
import { useEffect } from "react";
import { FormattedNumber, FormattedPlural } from "react-intl";
import { useTranslate } from "@tolgee/react";
import { T } from "@tolgee/react";
import DropArrow from "../assets/icons/drop_arrow.svg";
import { useSearchParams } from "react-router-dom";

function ResultsSort({ materials, sortby, setSortby, activeFilters }) {
  //const [searchParams, setSearchParams] = useSearchParams();

  //const sortingOption = searchParams.get("sortingOption") || "popular";
  // const setSortingOption = (selectedSortingOption) => {
  //   // copy existing queries to avoid mutation
  //   const newParams = new URLSearchParams(searchParams);
  //   newParams.set("sortingOption", selectedSortingOption);
  //   setSearchParams(newParams);
  // };

  // const activeFiltersString = searchParams.get("activeFilters");
  // const activeFilters = activeFiltersString ? JSON.parse(activeFiltersString) : {};

  const { t } = useTranslate();
  const { setDisplayMobileFilterModal } = useAppState();

  return (
    <>
      <div className=" flex justify-between  lg:mb-[24px] mb-[12px] ">
        <button onClick={() => setDisplayMobileFilterModal(true)} className="lg:hidden leading-[24px] text-[16px] font-semibold">
          <img src={FilterIcon} className="inline mr-[6px]" alt="open filters" />
          <T keyName="filter-title" />
          {"\u00A0"}
          {/* display how many filters are active, but only shown on mobile */}({Object.values(activeFilters).reduce((total, arr) => total + arr.length, 0)})
        </button>

        {/* results count (desktop) */}
        <p className="hidden lg:inline font-roboto my-auto leading-[18px] text-[14px] text-grey-4">
          <FormattedNumber value={materials.length} />
          &nbsp;
          <FormattedPlural value={materials.length} other={t({ key: "ResultsPage.p.results" })} one={t({ key: "ResultsPage.p.result" })} />
        </p>

        {/* selection option to sort results */}
        <div className="h-10">
          <p className="hidden lg:inline font-inter mr-2 leading-[18px] text-[14px]">
            <T keyName="ResultsPage.p.sort-by" />
          </p>
          <div className="relative inline-block  w-[162px]">
            <select
              onChange={(event) => setSortby(event.target.value)}
              // defaultValue="popular"
              value={sortby}
              className="bg-white pl-3 mx-auto py-[6px] font-normal w-full cursor-pointer leading-[24px] text-[16px] font-roboto border-[1px] border-solid border-grey-2 rounded-[4px]  appearance-none"
            >
              <option value="recommended">
                <T keyName="ResultsPage.select.sort-option.recommended" />
              </option>
              <option value="popular">
                <T keyName="ResultsPage.select.sort-option.popular" />
              </option>
              <option value="newest">
                <T keyName="ResultsPage.select.sort-option.newest" />
              </option>
            </select>
            <div className="absolute top-1/2 right-2 transform -translate-y-1/2 pointer-events-none">
              <img src={DropArrow} className="" alt="Dropdown Arrow" />
            </div>
          </div>
        </div>
      </div>

      {/* results count (mobile) */}

      <p className="lg:hidden font-roboto leading-[24px] text-[14px] text-grey-4 mb-[12px] ">
        <FormattedNumber value={materials.length} />
        &nbsp;
        <FormattedPlural value={materials.length} other={t({ key: "ResultsPage.p.results" })} one={t({ key: "ResultsPage.p.result" })} />
      </p>
    </>
  );
}

export default ResultsSort;
