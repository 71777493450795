import useAppState from "../store/appState";
import Filter from "./Filter";
import closeFilter from "../assets/icons/close_filter.svg";
import { useQueryClient } from "@tanstack/react-query";
import { T } from "@tolgee/react";
import { useSearchParams } from "react-router-dom";

function Overlay({ activeFilters, setActiveFilters, categories, materials, data }) {
  document.body.style.overflow = "hidden"; // prevent body scrolling

  const queryClient = useQueryClient();
  // const categories = queryClient.getQueryData(['categoryData']);

  const [searchParams, setSearchParams] = useSearchParams();
  //const activeFiltersString = searchParams.get("activeFilters");
  //const activeFilters = activeFiltersString ? JSON.parse(activeFiltersString) : {};

  // const setActiveFilters = (selectedFilters) => {
  //     setSearchParams({ activeFilters: JSON.stringify(selectedFilters) });
  // };

  const clearActiveFiltersFromURL = (selectedFilters) => {
    // const newParams = new URLSearchParams(searchParams);
    // newParams.delete("activeFilters");
    // setSearchParams(newParams);

    setActiveFilters({});
  };

  const { setDisplayMobileFilterModal } = useAppState();

  function overlayClosed() {
    setDisplayMobileFilterModal(false);
    document.body.style.overflow = "auto"; // allow body scrolling once modal closed
  }

  // Get all possible categories & generate an object with empty arrays ready to hold the selected filters by category
  // const generateInitialFilterState = () => {
  //     const initialState = {};
  //     categories.forEach((category) => {
  //         const categoryName = Object.keys(category)[0];
  //         initialState[categoryName] = [];
  //     });
  //     return initialState;
  // };

  return (
    <div className="bg-white h-screen w-screen fixed z-[10000] text-black overflow-y-auto  flex flex-col top-0">
      <div className="px-4 pt-[35px] bg-white  sticky z-[10000] top-0 left-0 right-0  ">
        <div className="flex justify-end  pb-[17px]">
          <button>
            <img src={closeFilter} onClick={() => overlayClosed()} alt="remove this filter item" className=" justify-end" />
          </button>
        </div>
        <p className="font-roboto leading-[28px] font-medium text-[24px]">
          <T keyName="filter-title" />
        </p>
        <hr className="mt-[20px] " />
      </div>
      <div className="px-4">
        <Filter activeFilters={activeFilters} setActiveFilters={setActiveFilters} categories={categories} materials={materials} allMaterials={data} />
      </div>

      <div className="fixed bottom-0 left-0 h-[80px] right-0 z-[10000] bg-white flex  py-4 justify-evenly items-center font-roboto leading-[24px] text-[16px] border-t-[1px] border-grey-2">
        <button className="bg-red-sp border-[2px]  border-red-sp w-[160px] text-white rounded-[50px] py-3 box-border" onClick={() => overlayClosed()}>
          <T keyName="filter-apply" />
        </button>

        {!Object.values(activeFilters).every((arr) => arr.length === 0) ? (
          <button
            className="bg-white w-[160px] border-[2px]  border-black text-black rounded-[50px] py-3 box-border"
            onClick={() => clearActiveFiltersFromURL()} // remove activeFilters from searchParams
          >
            <T keyName="filter-clear" /> ({Object.values(activeFilters).reduce((total, arr) => total + arr.length, 0)})
          </button>
        ) : (
          <button disabled={true} className="bg-white w-[160px] border-[2px] border-grey-3 text-grey-3 rounded-[50px] py-3 box-border">
            <T keyName="filter-clear" />
          </button>
        )}
      </div>
    </div>
  );
}

export default Overlay;
