import { useParams, Navigate } from "react-router-dom";

export default function Redirect() {
  const { seriesId, companyId, token } = useParams(); // useParams returns an object containing the url params - { species_name: 'url_value' }

  if (token != null) {
    localStorage["disd"] = token;
  }

  return <>{token != null ? <Navigate to={`/results`} /> : companyId != null ? <Navigate to={`/results/${companyId}/${seriesId}`} /> : <Navigate to={`/results/${seriesId}`} />}</>;
}
