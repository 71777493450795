import { useEffect, useState } from "react";
import useAppState from "../store/appState";
import ReactPaginate from "react-paginate";
import ResultsCard from "../components/ResultsCard";

import DropArrow from "../assets/icons/drop_arrow.svg";
import prevPage from "../assets/icons/pagination/chevron_left.svg";
import nextPage from "../assets/icons/pagination/chevron_right.svg";

import { useSearchParams } from "react-router-dom";

import { T } from "@tolgee/react";

function Items({ currentItems, defaultCountry }) {
  return (
    <ol className=" flex flex-col justify-between  lg:justify-between gap-y-6 md:gap-y-16 md:grid lg:gap-x-4 md:grid-cols-[repeat(auto-fill,minmax(343px,auto))]   lg:grid-cols-[repeat(auto-fill,minmax(185px,auto))] ">
      {currentItems && currentItems.map((material) => <ResultsCard key={material.id} material={material} defaultCountry={defaultCountry} />)}
    </ol>
  );
}

function ResultsPagination({ materials, defaultCountry }) {
  const { isMobile } = useAppState();
  const [searchParams, setSearchParams] = useSearchParams();

  //   const itemsPerPage = searchParams.get("itemsPerPage") || "12"; // default to 12 if not present in searchParams
  //   const setItemsPerPage = (newItemsPerPage) => {
  //     // copy existing queries to avoid mutation
  //     const newParams = new URLSearchParams(searchParams);
  //     newParams.set("itemsPerPage", newItemsPerPage);
  //     newParams.set("currentPage", 1);
  //     setSearchParams(newParams);
  //   };

  //   const currentPage = searchParams.get("currentPage") || 1; // default to 1 if not present in searchParams
  //   const setCurrentPage = (newCurrentPage) => {
  //     const pageValue = parseInt(newCurrentPage) + 1; // Increment by 1
  //     const newParams = new URLSearchParams(searchParams);
  //     newParams.set("currentPage", pageValue.toString()); // Set the incremented value
  //     setSearchParams(newParams);
  //   };

  //const [currentPage, setCurrentPage] = useState(0);

  const [itemsPerPage, setItemsPerPage] = useState(12);
  //const [itemOffset, setItemOffset] = useState(0);
  const itemOffset = parseInt(searchParams.get("itemOffset")) || 0;

  //const [currentPage, setCurrentPage] = useState(0);
  const currentPage = parseInt(searchParams.get("currentPage")) || 0;

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = e.target.value;
    setItemsPerPage(newItemsPerPage);

    //setItemOffset(0);

    ////setCurrentPage(0); // reset to the first page
    const params = new URLSearchParams(searchParams);
    params.set("currentPage", 0);
    params.set("itemOffset", 0);
    setSearchParams(params.toString());

    if (isMobile) {
      window.scrollTo(0, 310);
    } else window.scrollTo(0, 400);
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newPage = event.selected;

    const newOffset = (newPage * itemsPerPage) % materials.length;

    //setItemOffset(newOffset);

    const params = new URLSearchParams(searchParams);
    params.set("currentPage", newPage);
    params.set("itemOffset", newOffset);
    setSearchParams(params.toString());
    ////setCurrentPage(event.selected);

    //setCurrentPage(selectedPage.selected);
    if (isMobile) {
      window.scrollTo(0, 310);
    } else window.scrollTo(0, 400);
  };

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    // Fetch items from another resource.
    //const newOffset = (currentPage - 1) * itemsPerPage; // Adjusted calculation
    setCurrentItems(materials.slice(itemOffset, itemOffset + parseInt(itemsPerPage)));
    setPageCount(Math.ceil(materials.length / parseInt(itemsPerPage)));
  }, [itemOffset, itemsPerPage, materials]);

  return (
    <>
      <Items currentItems={currentItems} defaultCountry={defaultCountry} />
      <div className="flex flex-col xl:flex-row justify-end gap-x-10  mt-[48px]   ">
        <div className=" flex items-center justify-end  mb-[30px] xl:mb-0">
          <p className="font-inter inline mr-2 leading-[18px] text-[14px] ">
            <T keyName="ResultsPagination.p.results-per-page" />
          </p>
          <div className="relative inline-block  text-black w-[70px] ">
            <select
              onChange={handleItemsPerPageChange}
              value={itemsPerPage}
              // defaultValue="12"
              className="bg-white pl-3 mx-auto py-[6px] font-normal w-full cursor-pointer leading-[24px] text-[16px] font-roboto border-[1px] border-solid border-grey-2 rounded-[4px]  appearance-none"
            >
              <option value="6">6</option>
              <option value="12">12</option>
              <option value="24">24</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <div className="absolute top-1/2 right-2 transform -translate-y-1/2 pointer-events-none">
              <img src={DropArrow} className="" alt="Dropdown Arrow" />
            </div>
          </div>
        </div>

        {pageCount > 0 && (
          <ReactPaginate
            nextLabel={<img className="flex leading-[24px] text-[16px] text-grey-2-sp font-roboto items-center align-middle justify-center" src={nextPage} />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel={<img src={prevPage} />}
            pageClassName="flex flex-row items-center justify-center flex h-9 w-9 leading-[24px] text-[16px] text-grey-2-sp font-roboto "
            // initialPage={currentPage - 1 || 0}
            forcePage={currentPage}
            activeClassName="bg-red-sp text-white rounded-full"
            pageLinkClassName=""
            previousClassName="flex items-center"
            previousLinkClassName="page-link"
            nextClassName="flex items-center"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="flex leading-[24px] text-[16px] text-grey-2-sp font-roboto items-center "
            breakLinkClassName=""
            containerClassName="flex flex-row justify-between w-full xl:w-[320px] select-none "
            renderOnZeroPageCount={null}
          />
        )}
      </div>
    </>
  );
}
export default ResultsPagination;
