import { useEffect, useState } from "react";
import SearchIcon from "../assets/icons/search.svg";
import ClearIcon from "../assets/icons/cancel.svg";
import { useTranslate } from "@tolgee/react";
import useAppState from "../store/appState";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

function SearchBox() {
  const location = useLocation();
  const navigate = useNavigate();

  const { isMobile, setSearchResults } = useAppState();

  const { t } = useTranslate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("searchTerm") || "");
  const [activeFilters, setActiveFilters] = useState(searchParams.get("activeFilters") || "");

  function handleSubmit(e) {
    e.preventDefault();

    if (location.pathname !== "/results") {
      navigate(`/results?searchTerm=${searchTerm}`);
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("searchTerm", searchTerm);
      setSearchParams(newParams);
    }
  }

  function handleClear() {
    setSearchTerm("");
    const newParams = new URLSearchParams(searchParams);
    newParams.set("searchTerm", "");
    setSearchParams(newParams);
  }

  return (
    <form
      id="topOfResults"
      onSubmit={handleSubmit}
      className="justify-between align-baseline bg-white pl-5 flex flex-row items-center text-gray-500 border-2 border-gray-2 rounded-[50px] h-[58px] w-full lg:w-[798px]"
    >
      <input
        className="placeholder-grey-search-placeholder leading-[24px] text-[18px] focus:outline-none text-black w-[230px] lg:w-[674px] lg:flex flex-row items-center bg-opacity-0 text-left text-grey-04 font-roboto"
        type="text"
        value={searchTerm}
        placeholder={isMobile ? t({ key: "SearchBox.input.placeholder.mobile" }) : t({ key: "SearchBox.input.placeholder.desktop" })}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div className="flex flex-row align-middle gap-x-2">
        {searchTerm && (
          <button
            type="button"
            onClick={() => {
              handleClear();
            }}
          >
            <img src={ClearIcon} alt={t({ key: "SearchBox.button.clear" })} />
          </button>
        )}

        <button type="submit" className="pr-2" aria-label={t({ key: "SearchBox.button.submit" })}>
          <img className="h-[42px] w-[42px]" src={SearchIcon} alt={t({ key: "SearchBox.button.submit" })} />
        </button>
      </div>
    </form>
  );
}

export default SearchBox;
